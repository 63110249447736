<!-- QuestionView.vue -->
<template>
  <master>
    <template #header>
      <div class="user-header">
        <img :src="user.image" alt="User Image" class="user-image" />
        <span class="user-name">{{ user.name }}</span>
      </div>
    </template>
    <div class="question-card">
      <IonIcon :icon="starOutline" class="question-icon" />
      <p class="question-text">{{ question }}</p>
      <div class="star-rating">
        <IonIcon
          v-for="index in 5"
          :key="index"
          :icon="index <= rating ? star : starOutline"
          class="star-icon"
          @click="setRating(index)"
        />
      </div>
      <button class="next-button" @click="nextQuestion">Next →</button>
    </div>
  </master>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import {
  IonIcon,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
} from "@ionic/vue";
import { starOutline, star } from "ionicons/icons";

import { useGeneralStore } from "@/stores/useGeneralStore";
import ApiService from "@/common/service.api";

import master from "../layout/master.vue";

const generalStore = useGeneralStore();

const loading = ref({
  gen: false,
});

const router = useRouter();
const currentRisposta = ref(null);
const survey = computed(() => generalStore.currentSurvey);
const selectedRating = ref(null);
const showFinalCard = ref(false);

const HIGHLIGHT_DELAY = 1000; // 1 second delay before moving to the next question

// Lifecycle
onMounted(() => {
  startQuiz();
});

// Methods
function startQuiz() {
  console.log("erogazione", survey.value);

  if (survey.value.risposte.length > 0) {
    currentRisposta.value = survey.value.risposte[0];
  }
}

function getcurrentRisposta() {
  const currentIndex = survey.value.risposte.findIndex(
    (risposta) => risposta.id === currentRisposta.value.id
  );
  if (currentIndex >= 0 && currentIndex < survey.value.risposte.length - 1) {
    currentRisposta.value = survey.value.risposte[currentIndex + 1];
    selectedRating.value = null; // Reset rating for the next question
  } else {
    showFinalCard.value = true; // Show final card to confirm answers
  }
}

function handleRatingClick(e) {
  if (currentRisposta.value.data_risposta || isSurveyCompleteOrDeleted())
    return;
  currentRisposta.value.valore = e;
  // Highlight selected stars before moving to the next question
  setTimeout(() => {
    getcurrentRisposta();
  }, HIGHLIGHT_DELAY);
}

function isSurveyCompleteOrDeleted() {
  return survey.value.completato || survey.value.eliminato;
}

async function confirmCompletion(isConfirmed) {
  if (isConfirmed) {
    survey.value.completato = new Date().toISOString();
    await handleSaveRisposte();

    router.push({ name: "Surveys" });
  } else {
    // Restart quiz from the beginning
    startQuiz();
    showFinalCard.value = false;
  }
}

async function handleSaveRisposte() {
  loading.value.gen = true;
  try {
    for (let risposta of survey.value.risposte) {
      await ApiService.put(`/Survey/Risposta/${risposta.id}`, risposta);
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value.gen = false;
  }
}
</script>

<style scoped>
.user-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.user-image {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.user-name {
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
}

.question-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.question-icon {
  font-size: 40px;
  color: #ffcc00;
}

.question-text {
  font-size: 18px;
  margin: 20px 0;
}

.star-rating {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.star-icon {
  font-size: 30px;
  cursor: pointer;
  margin: 0 5px;
  color: #ffcc00;
}

.next-button {
  background-color: #4a90e2;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>
